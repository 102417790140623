<template>
  <!-- 新闻动态 -->
  <div class="content">
    <div class="newsHeader">
      <h1>媒体中心</h1>
      <span><h3>新闻动态</h3></span>
    </div>
    <div class="newsContent" v-for="news, index in news_info" :key="index">
      <div class="newsLeft">
        <img :src="news.img_url" alt="">
      </div>
      <div class="newsRight">
        <h3>{{news.title}}</h3>
        <span>{{news.time}}</span>
        <p>{{news.desc}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      news_info: [
        { 
          img_url: require("@/assets/images/news/10.png"), 
          title: "长飞亚亮相全球最大的橡塑展会K 2022--电动注塑技术，恰到好处",  
          time: "2022-09-30",
          desc: "长飞亚将携三项最新技术成果，亮相三年一届的德国K展，全面演示其高效节能、应用广泛的电动注塑解决方案。毋庸置疑，长飞亚的电动注塑技术已成功拉低了电动解决方案的准入门槛，并使其成为欧洲很多塑料加工业者的首选。更重要的是，它降低了工厂运营的成本：事实证明，与传统液压机相比，长飞亚电动机运行更精确，能效更高，可达70%。这也是长飞亚品牌的传统优势之一。在K2022，我们将集中展示面向客户实际需求的“恰到好处”的电动技术，全面提升客户的核心竞争力。",
        },
        { 
          img_url: require("@/assets/images/news/10.png"), 
          title: "长飞亚亮相全球最大的橡塑展会K 2022--电动注塑技术，恰到好处",  
          time: "2022-09-30",
          desc: "长飞亚将携三项最新技术成果，亮相三年一届的德国K展，全面演示其高效节能、应用广泛的电动注塑解决方案。毋庸置疑，长飞亚的电动注塑技术已成功拉低了电动解决方案的准入门槛，并使其成为欧洲很多塑料加工业者的首选。更重要的是，它降低了工厂运营的成本：事实证明，与传统液压机相比，长飞亚电动机运行更精确，能效更高，可达70%。这也是长飞亚品牌的传统优势之一。在K2022，我们将集中展示面向客户实际需求的“恰到好处”的电动技术，全面提升客户的核心竞争力。",
        },
        { 
          img_url: require("@/assets/images/news/10.png"), 
          title: "长飞亚亮相全球最大的橡塑展会K 2022--电动注塑技术，恰到好处",  
          time: "2022-09-30",
          desc: "长飞亚将携三项最新技术成果，亮相三年一届的德国K展，全面演示其高效节能、应用广泛的电动注塑解决方案。毋庸置疑，长飞亚的电动注塑技术已成功拉低了电动解决方案的准入门槛，并使其成为欧洲很多塑料加工业者的首选。更重要的是，它降低了工厂运营的成本：事实证明，与传统液压机相比，长飞亚电动机运行更精确，能效更高，可达70%。这也是长飞亚品牌的传统优势之一。在K2022，我们将集中展示面向客户实际需求的“恰到好处”的电动技术，全面提升客户的核心竞争力。",
        },
        { 
          img_url: require("@/assets/images/news/10.png"), 
          title: "长飞亚亮相全球最大的橡塑展会K 2022--电动注塑技术，恰到好处",  
          time: "2022-09-30",
          desc: "长飞亚将携三项最新技术成果，亮相三年一届的德国K展，全面演示其高效节能、应用广泛的电动注塑解决方案。毋庸置疑，长飞亚的电动注塑技术已成功拉低了电动解决方案的准入门槛，并使其成为欧洲很多塑料加工业者的首选。更重要的是，它降低了工厂运营的成本：事实证明，与传统液压机相比，长飞亚电动机运行更精确，能效更高，可达70%。这也是长飞亚品牌的传统优势之一。在K2022，我们将集中展示面向客户实际需求的“恰到好处”的电动技术，全面提升客户的核心竞争力。",
        },
        { 
          img_url: require("@/assets/images/news/10.png"), 
          title: "长飞亚亮相全球最大的橡塑展会K 2022--电动注塑技术，恰到好处",  
          time: "2022-09-30",
          desc: "长飞亚将携三项最新技术成果，亮相三年一届的德国K展，全面演示其高效节能、应用广泛的电动注塑解决方案。毋庸置疑，长飞亚的电动注塑技术已成功拉低了电动解决方案的准入门槛，并使其成为欧洲很多塑料加工业者的首选。更重要的是，它降低了工厂运营的成本：事实证明，与传统液压机相比，长飞亚电动机运行更精确，能效更高，可达70%。这也是长飞亚品牌的传统优势之一。在K2022，我们将集中展示面向客户实际需求的“恰到好处”的电动技术，全面提升客户的核心竞争力。",
        },
      ]
    };
  },
};
</script>

<style lang="less" scoped>
.newsHeader span {
  display: block;
  width: 135px;
  padding: 20px 0;
  border-bottom: 2px solid #ccc;
}
.newsContent {
  height: 260px;
  margin: 50px auto;
  border: 1px solid #ccc;
  .newsLeft {
    float: left;
    margin: 55px 0 0 30px;
  }
  .newsRight {
    float: right;
    width: 900px;
    margin-top: 30px;
    h3 {
      font-weight: 500;
      color: #143a84;
    }
    span {
      display: block;
      margin-top: 10px;
    }
    p {
      font-weight: 100;
      margin: 10px 30px 0 0;
      font-size: 15px;
    }
  }
}
</style>